import React from 'react';
import Button from '../Button';
import Carousel from 'react-bootstrap/Carousel';
import './styles.scss';

const GlassDoor = () => {
    return (
        <div className='glass-door-wrap'>
            <div className='glass-door-header'>
                <h4>Our Glassdoor Ratings</h4>
            </div>
            <div className='glass-door-inner'>
                <div className='glass-door'>
                    <img
                        src={'https://sw-assets.gumlet.io/about-us/rating.svg'}
                        data-src={'https://sw-assets.gumlet.io/about-us/rating.svg'}
                        alt={'rating'}
                    />
                    <p>Glassdoor Rating</p>
                </div>

                <div className='glass-door'>
                    <img
                        src={'https://sw-assets.gumlet.io/about-us/business.svg'}
                        data-src={'https://sw-assets.gumlet.io/about-us/business.svg'}
                        alt={'business'}
                    />
                    <p>Positive Business Outlook</p>
                </div>

                <div className='glass-door'>
                    <img
                        src={'https://sw-assets.gumlet.io/about-us/approval.svg'}
                        data-src={'https://sw-assets.gumlet.io/about-us/approval.svg'}
                        alt={'approval'}
                    />
                    <p>CEO Approval</p>
                </div>

                <div className='glass-door'>
                    <img
                        src={'https://sw-assets.gumlet.io/about-us/recommed.svg'}
                        data-src={'https://sw-assets.gumlet.io/about-us/recommed.svg'}
                        alt={'recommend'}
                    />
                    <p>Recommended to a friend</p>
                </div>
            </div>

            <Carousel className='glass-door-slider'>
                <Carousel.Item>
                    <div className='glass-door'>
                        <img
                            src={'https://sw-assets.gumlet.io/about-us/rating.svg'}
                            data-src={'https://sw-assets.gumlet.io/about-us/rating.svg'}
                            alt={'rating'}
                        />
                        <p>Glassdoor Rating</p>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='glass-door'>
                        <img
                            src={'https://sw-assets.gumlet.io/about-us/business.svg'}
                            data-src={'https://sw-assets.gumlet.io/about-us/business.svg'}
                            alt={'business'}
                        />
                        <p>Positive Business Outlook</p>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='glass-door'>
                        <img
                            src={'https://sw-assets.gumlet.io/about-us/approval.svg'}
                            data-src={'https://sw-assets.gumlet.io/about-us/approval.svg'}
                            alt={'approval'}
                        />
                        <p>CEO Approval</p>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='glass-door'>
                        <img
                            src={'https://sw-assets.gumlet.io/about-us/recommed.svg'}
                            data-src={'https://sw-assets.gumlet.io/about-us/recommed.svg'}
                            alt={'recommend'}
                        />
                        <p>Recommended to a friend</p>
                    </div>
                </Carousel.Item>
            </Carousel>

            <Button buttonText='JOIN OUR TEAM' redirectTo={process.env.GATSBY_SW_CAREER_PAGE} />
        </div>
    );
};

export default GlassDoor;
