import React from 'react';
import Button from '../Button';
import Carousel from 'react-bootstrap/Carousel';
import './styles.scss';

const PressRelease = () => {
    return (
        <div className='press-release-wrap'>
            <div className='sw-container'>
                <div className='press-release'>
                    <div className='press-release-section-header'>
                        <h2>
                            Press
                            <br />
                            release
                        </h2>
                    </div>
                    <div className='press-release-section-header press-header-mob'>
                        <h2>Press Release</h2>
                        <p>
                            Springworks is the embodiment of our vision and ambition for building the tools and products
                            to simplify{' '}
                        </p>
                    </div>
                    <div className='press-release-section-right'>
                        <div className='press-release-card-wrap'>
                            <div className='press-release-card'>
                                <div className='card-logo'>
                                    <img
                                        alt={'factory-daily'}
                                        data-src={'https://sw-assets.gumlet.io/about-us/factory-daily.svg'}
                                        src={'https://sw-assets.gumlet.io/about-us/factory-daily.svg'}
                                    />
                                </div>
                                <div className='card-content'>
                                    <h4>Bengaluru hiring company SpringRole wants to be a LinkedIn on blockchain </h4>
                                    <a
                                        href='https://factordaily.com/bengaluru-hiring-company-springrole-wants-to-be-a-linkedin-on-blockchain/'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Read....
                                    </a>
                                </div>
                            </div>

                            <div className='press-release-card'>
                                <div className='card-logo'>
                                    <img
                                        alt={'economic-times'}
                                        data-src={'https://sw-assets.gumlet.io/about-us/economic-times.svg'}
                                        src={'https://sw-assets.gumlet.io/about-us/economic-times.svg'}
                                    />
                                </div>
                                <div className='card-content'>
                                    <h4>
                                        SpringRole joins Matic Developer Support Program, offers $250K boost to
                                        blockchain cos
                                    </h4>
                                    <a
                                        href='https://economictimes.indiatimes.com/tech/software/springrole-joins-matic-developer-support-program-offers-250k-boost-to-blockchain-cos/articleshow/77776006.cms'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Read....
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='press-release-card-wrap'>
                            <div className='press-release-card'>
                                <div className='card-logo'>
                                    <img
                                        alt={'business-standard'}
                                        data-src={'https://sw-assets.gumlet.io/about-us/business-standard.svg'}
                                        src={'https://sw-assets.gumlet.io/about-us/business-standard.svg'}
                                    />
                                </div>
                                <div className='card-content'>
                                    <h4>Blockchain-powered recruiting startup SpringRole weeds out fake resumes </h4>
                                    <a
                                        href='https://www.business-standard.com/article/companies/blockchain-powered-recruiting-startup-springrole-weeds-out-fake-resumes-118052700580_1.html/'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Read....
                                    </a>
                                </div>
                            </div>

                            <div className='press-release-card'>
                                <div className='card-logo'>
                                    <img
                                        alt={'your-story'}
                                        data-src={'https://sw-assets.gumlet.io/about-us/your-story.svg'}
                                        src={'https://sw-assets.gumlet.io/about-us/your-story.svg'}
                                    />
                                </div>
                                <div className='card-content'>
                                    <h4>Springworks rolls out WhatsApp tool to verify govt IDs instantly</h4>
                                    <a
                                        href='https://yourstory.com/2020/02/springworks-whatsapp-tool-verify-government-id-fake/'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Read....
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='press-release-card-wrap'>
                            <div className='press-release-card'>
                                <div className='card-logo'>
                                    <img
                                        alt={'teach-panda'}
                                        data-src={'https://sw-assets.gumlet.io/about-us/teach-panda.svg'}
                                        src={'https://sw-assets.gumlet.io/about-us/teach-panda.svg'}
                                    />
                                </div>
                                <div className='card-content'>
                                    <h4>Trivia For Remote Teams to Bond Over Games and Quizzes ....</h4>
                                    <a
                                        href='https://thetechpanda.com/dream-apps-trivia-for-remote-teams-to-bond-over-games-and-quizzes-right-inside-slack-or-ms-teams/31384/'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Read....
                                    </a>
                                </div>
                            </div>

                            <div className='press-release-card'>
                                <div className='card-logo'>
                                    <img
                                        alt={'mint'}
                                        data-src={'https://sw-assets.gumlet.io/about-us/mint.svg'}
                                        src={'https://sw-assets.gumlet.io/about-us/mint.svg'}
                                    />
                                </div>
                                <div className='card-content'>
                                    <h4>‘Karma points’ keep employees motivated</h4>
                                    <a
                                        href='https://www.livemint.com/news/india/-karma-points-keep-employees-motivated-11596023915369.html'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Read....
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Carousel className='glass-door-slider press-slider '>
                <Carousel.Item>
                    <div className='press-release-card'>
                        <div className='card-logo'>
                            <img
                                alt={'factory-daily'}
                                data-src={'https://sw-assets.gumlet.io/about-us/factory-daily.svg'}
                                src={'https://sw-assets.gumlet.io/about-us/factory-daily.svg'}
                            />
                        </div>
                        <div className='card-content'>
                            <h4>Bengaluru hiring company SpringRole wants to be a LinkedIn on blockchain </h4>
                            <a
                                href='https://factordaily.com/bengaluru-hiring-company-springrole-wants-to-be-a-linkedin-on-blockchain/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Read....
                            </a>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='press-release-card'>
                        <div className='card-logo'>
                            <img
                                alt={'economic-times'}
                                data-src={'https://sw-assets.gumlet.io/about-us/economic-times.svg'}
                                src={'https://sw-assets.gumlet.io/about-us/economic-times.svg'}
                            />
                        </div>
                        <div className='card-content'>
                            <h4>
                                SpringRole joins Matic Developer Support Program, offers $250K boost to blockchain cos
                            </h4>
                            <a
                                href='https://economictimes.indiatimes.com/tech/software/springrole-joins-matic-developer-support-program-offers-250k-boost-to-blockchain-cos/articleshow/77776006.cms'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Read....
                            </a>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='press-release-card'>
                        <div className='card-logo'>
                            <img
                                alt={'business-standard'}
                                data-src={'https://sw-assets.gumlet.io/about-us/business-standard.svg'}
                                src={'https://sw-assets.gumlet.io/about-us/business-standard.svg'}
                            />
                        </div>
                        <div className='card-content'>
                            <h4>Blockchain-powered recruiting startup SpringRole weeds out fake resumes </h4>
                            <a
                                href='https://www.business-standard.com/article/companies/blockchain-powered-recruiting-startup-springrole-weeds-out-fake-resumes-118052700580_1.html/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Read....
                            </a>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='press-release-card'>
                        <div className='card-logo'>
                            <img
                                alt={'your-story'}
                                data-src={'https://sw-assets.gumlet.io/about-us/your-story.svg'}
                                src={'https://sw-assets.gumlet.io/about-us/your-story.svg'}
                            />
                        </div>
                        <div className='card-content'>
                            <h4>Springworks rolls out WhatsApp tool to verify govt IDs instantly</h4>
                            <a
                                href='https://yourstory.com/2020/02/springworks-whatsapp-tool-verify-government-id-fake/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Read....
                            </a>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='press-release-card'>
                        <div className='card-logo'>
                            <img
                                alt={'teach-panda'}
                                data-src={'https://sw-assets.gumlet.io/about-us/teach-panda.svg'}
                                src={'https://sw-assets.gumlet.io/about-us/teach-panda.svg'}
                            />
                        </div>
                        <div className='card-content'>
                            <h4>Trivia For Remote Teams to Bond Over Games and Quizzes ....</h4>
                            <a
                                href='https://thetechpanda.com/dream-apps-trivia-for-remote-teams-to-bond-over-games-and-quizzes-right-inside-slack-or-ms-teams/31384/'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Read....
                            </a>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='press-release-card'>
                        <div className='card-logo'>
                            <img
                                alt={'mint'}
                                data-src={'https://sw-assets.gumlet.io/about-us/mint.svg'}
                                src={'https://sw-assets.gumlet.io/about-us/mint.svg'}
                            />
                        </div>
                        <div className='card-content'>
                            <h4>‘Karma points’ keep employees motivated</h4>
                            <a
                                href='https://www.livemint.com/news/india/-karma-points-keep-employees-motivated-11596023915369.html'
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                Read....
                            </a>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
            <Button buttonText='JOIN OUR TEAM' redirectTo={process.env.GATSBY_SW_CAREER_PAGE} />
        </div>
    );
};

export default PressRelease;
