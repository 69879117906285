import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from '../Button';
import './styles.scss';

const Culture = () => {
    return (
        <div className='sw-culture-wrap'>
            <div className='sw-container'>
                <div className='section-header'>
                    <h2>office family & culture</h2>
                    <p>We're always excited about buddy lunches, sports days, excursions and festive celebrations!</p>
                </div>
            </div>
            <div className='sw-culture-imagery'>
                <div className='sw-culture-img'>
                    <img
                        src={'https://sw-assets.gumlet.io/about-us/culture-01.png?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/about-us/culture-01.png'}
                        alt={'culture'}
                    />
                    <div className='sw-culture-content'>
                        <h4>Buddy Lunch</h4>
                    </div>
                </div>
                <div className='sw-culture-img'>
                    <img
                        src={'https://sw-assets.gumlet.io/about-us/culture-02.png?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/about-us/culture-02.png'}
                        alt={'culture'}
                    />
                    <div className='sw-culture-content'>
                        <h4>Sports Day</h4>
                    </div>
                </div>
                <div className='sw-culture-img'>
                    <img
                        src={'https://sw-assets.gumlet.io/about-us/culture-03.png?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/about-us/culture-03.png'}
                        alt={'culture'}
                    />
                    <div className='sw-culture-content'>
                        <h4>Intern Outings</h4>
                    </div>
                </div>
                <div className='sw-culture-img'>
                    <img
                        src={'https://sw-assets.gumlet.io/about-us/culture-04.png?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/about-us/culture-04.png'}
                        alt={'culture'}
                    />
                    <div className='sw-culture-content'>
                        <h4>Festivals</h4>
                    </div>
                </div>
            </div>

            <Carousel>
                <Carousel.Item>
                    <div className='sw-culture-img'>
                        <img
                            src={'https://sw-assets.gumlet.io/about-us/culture-01.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/about-us/culture-01.png'}
                            alt={'culture'}
                        />
                        <div className='sw-culture-content'>
                            <h4>Buddy Lunch</h4>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='sw-culture-img'>
                        <img
                            src={'https://sw-assets.gumlet.io/about-us/culture-02.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/about-us/culture-02.png'}
                            alt={'culture'}
                        />
                        <div className='sw-culture-content'>
                            <h4>Sports Day</h4>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='sw-culture-img'>
                        <img
                            src={'https://sw-assets.gumlet.io/about-us/culture-03.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/about-us/culture-03.png'}
                            alt={'culture'}
                        />
                        <div className='sw-culture-content'>
                            <h4>Intern Outings</h4>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='sw-culture-img'>
                        <img
                            src={'https://sw-assets.gumlet.io/about-us/culture-04.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/about-us/culture-04.png'}
                            alt={'culture'}
                        />
                        <div className='sw-culture-content'>
                            <h4>Festivals</h4>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>

            <div className='sw-container'>
                <div className='culture-join-team'>
                    <p>
                        Springworks announced its remote-first future on May 6, 2020. Join our team and be a part of a
                        culture that celebrates flexibility and unparalleled work-life harmony.
                    </p>
                    <Button buttonText='JOIN OUR TEAM' redirectTo={process.env.GATSBY_SW_CAREER_PAGE} />
                </div>
            </div>
        </div>
    );
};

export default Culture;
