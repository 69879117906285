import React from 'react';
import './styles.scss';
import { Link } from 'gatsby';

const Community = () => {
    return (
        <div className='sw-executive-team community-wrap'>
            <div className='sw-container'>
                <div className='section-header community-header'>
                    <h2>COMMUNITY INITIATIVES</h2>
                </div>
                <div className='sw-community-wrap'>
                    <div className='sw-community'>
                        <p>We set up a kitchen amidst the lockdown to cook food for the less fortunate.</p>
                        <p>Today, we’ve distributed food to more than 9,000 people in Bengaluru.</p>
                        <p>Here’s the story behind our initiative.</p>

                        <Link to={'/giving-back/'}>Learn More</Link>
                    </div>
                    <div className='sw-community'>
                        <img
                            src={'https://sw-assets.gumlet.io/about-us/springworks-community.jpg'}
                            data-src={'https://sw-assets.gumlet.io/about-us/springworks-community.jpg'}
                            alt='Springworks Community'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Community;
