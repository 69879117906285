import React from 'react';
import './styles.scss';

const WhoAreWe = () => {
    return (
        <div className='sw-container'>
            <div className='who-are-we' id='aboutUs'>
                <div className='section-header'>
                    <h2>Who We Are</h2>
                    <p>We are bringing together technology, intelligence and human emotion.</p>
                    <p>We are changing the way we work. We are Springworks.</p>
                </div>
                <div className='who-are-we-video'>
                    <iframe
                        title={'Who are we?'}
                        src='https://www.youtube-nocookie.com/embed/0VIgg0MKTD4?controls=0'
                        frameBorder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                    />
                </div>
            </div>
        </div>
    );
};

export default WhoAreWe;
