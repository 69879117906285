import React from 'react';
import './styles.scss';

const Team = () => {
    return (
        <div className='sw-executive-team'>
            <div className='sw-container'>
                <div className='section-header'>
                    <h2>Executive Team</h2>
                </div>
                <div className='sw-team-wrap'>
                    <div className='sw-team'>
                        <div className='sw-team-header'>
                            <h2>Kartik Mandaville</h2>
                            <p>CEO and Founder</p>
                        </div>
                        <div className='sw-team-img'>
                            <img
                                src={'https://sw-assets.gumlet.io/about-us/kartik.png?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/about-us/kartik.png'}
                                alt={'Kartik Mandaville'}
                            />
                        </div>
                    </div>
                    <div className='sw-team-content-wrap'>
                        <div className='sw-team-content'>
                            <div className='sw-team-header'>
                                <h2>Kartik Mandaville</h2>
                                <p>CEO and Founder</p>
                            </div>
                            <div className='sw-team-para'>
                                <p>
                                    Kartik Mandaville is Chief Executive Officer and Founder of Springworks. While
                                    building contemporary solutions for the HR industry of the future, Kartik also
                                    serves as the Senior Technical Advisor at Science-Inc and Acting CTO at Plowz & Mowz
                                    and Burst.
                                </p>
                            </div>

                            <div className='team-sc-link'>
                                <a href='https://twitter.com/kar2905/' target='_blank' rel='noreferrer noopener'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/twitter-blue.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/twitter-blue.svg'}
                                        alt={'twitter'}
                                    />
                                </a>
                                <a
                                    href='https://www.linkedin.com/in/kar2905/'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/linkedin-blue.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/linkedin-blue.svg'}
                                        alt={'linkedin'}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sw-team-wrap sw-team-wrap-left'>
                    <div className='sw-team-content-wrap'>
                        <div className='sw-team-content'>
                            <div className='sw-team-header'>
                                <h2>Abhash Kumar</h2>
                                <p>Marketing Head</p>
                            </div>
                            <div className='sw-team-para'>
                                <p>
                                    With over 9 years of experience that includes being an early employee at high-growth
                                    companies like BYJU's, YourStory and now at Springworks, Abhash thrives in early and
                                    rapid-growth-stage startup environments.
                                </p>
                                <p>
                                    His first stint was in Sales and BD with BYJU's, then he went on to take up roles in
                                    Community and startup-focused journalism, eventually finding his knack for
                                    Marketing. In his previous roles, he has headed the Digital Marketing and Growth
                                    team at YourStory
                                </p>
                            </div>
                            <div className='team-sc-link'>
                                <a href='https://twitter.com/AabhashK/' target='_blank' rel='noreferrer noopener'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/twitter-blue.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/twitter-blue.svg'}
                                        alt={'twitter'}
                                    />
                                </a>
                                <a
                                    href='https://www.linkedin.com/in/aabhashk/'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/linkedin-blue.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/linkedin-blue.svg'}
                                        alt={'linkedin'}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='sw-team'>
                        <div className='sw-team-img'>
                            <img
                                src={'https://sw-assets.gumlet.io/about-us/abhash.png?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/about-us/abhash.png'}
                                alt={'Abhash Kumar'}
                            />
                        </div>
                    </div>
                </div>

                <div className='sw-team-wrap'>
                    <div className='sw-team'>
                        <div className='sw-team-img'>
                            <img
                                src={'https://sw-assets.gumlet.io/about-us/anoop.png?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/about-us/anoop.png'}
                                alt={'Anoop Suresh'}
                            />
                        </div>
                    </div>
                    <div className='sw-team-content-wrap'>
                        <div className='sw-team-content'>
                            <div className='sw-team-header'>
                                <h2>Anoop Suresh</h2>
                                <p>Head of Business & Operations</p>
                            </div>
                            <div className='sw-team-para'>
                                <p>
                                    Anoop heads Business & Operations at Springworks, overseeing new avenues to drive
                                    growth and creating strategic partnerships. An entrepreneur with over 20 years of
                                    experience across multiple domains and prestigious brands, he is highly adept at
                                    building technology focussed businesses with a strong emphasis on creating great
                                    products.
                                </p>
                            </div>
                            <div className='team-sc-link'>
                                <a
                                    href='https://www.linkedin.com/in/anoopsuresh1/'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/linkedin-blue.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/linkedin-blue.svg'}
                                        alt={'linkedin'}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;
