import React from 'react';
import Navbar from '../../components/springworks/Navbar';
import Header from '../../components/about-us/Header';
import HeaderContainer from '../../components/springworks/HeaderContainer';
import WhoWeAre from '../../components/about-us/WhoWeAre';
import Team from '../../components/about-us/Team';
import GlassDoor from '../../components/about-us/GlassDoor';
import Culture from '../../components/about-us/Culture';
import PressRelease from '../../components/about-us/PressRelease';
import Footer from '../../components/springworks/Footer';
import './styles.scss';
import { Helmet } from 'react-helmet';
import Community from '../../components/about-us/Community';

const AboutUs = () => {
    return (
        <>
            <Helmet>
                <title>About Us | Springworks</title>
                <meta
                    data-react-helmet='true'
                    name='description'
                    content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
                />
                <meta property='og:site_name' content='Springworks' />
                <meta property='og:title' content='About Us | Springworks' />
                <meta
                    property='og:description'
                    content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
                />
                <meta property='og:url' content='https://www.springworks.in/about-us/' />
                <meta property='og:type' content='website' />
                <meta
                    property='og:image'
                    content='https://assets-springworks.s3.amazonaws.com/SpringworksAboutUs.jpg'
                />
                <meta
                    property='og:image:secure_url'
                    content='https://assets-springworks.s3.amazonaws.com/SpringworksAboutUs.jpg'
                />
                <meta property='og:image:width' content='1280' />
                <meta property='og:image:height' content='640' />
                <meta property='twitter:title' content='About Us | Springworks' />
                <meta
                    property='twitter:description'
                    content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
                />
                <meta property='twitter:card' content='website' />
                <meta property='twitter:card' content='summary_large_image' />
                <meta
                    property='twitter:image'
                    content='https://assets-springworks.s3.amazonaws.com/SpringworksAboutUs.jpg'
                />
                <meta property='twitter:site' content='@springroleinc' />
                <link rel='canonical' href='https://www.springworks.in/about-us/' />
            </Helmet>
            <div className='about-us-wrap'>
                <HeaderContainer>
                    <Navbar />
                    <Header />
                </HeaderContainer>
                <WhoWeAre />
                <GlassDoor />
                <Team />
                <Community />
                <Culture />
                <PressRelease />
                <Footer />
            </div>
        </>
    );
};

export default AboutUs;
