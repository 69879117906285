import React from 'react';
import Button from '../Button';
import './styles.scss';

const Header = () => {
    return (
        <div className='sw-about-us-header'>
            <div className='sw-about-us-left'>
                <h1>ABOUT US</h1>
                <div className='sw-about-us-para'>
                    <p>
                        Springworks is the embodiment of our vision and ambition to revolutionise the HR landscape with
                        products that serve businesses in the most human way.
                    </p>
                    <p>We are leading the world to the future of HR which should have been ours already.</p>
                </div>
                <Button buttonText='JOIN OUR TEAM' redirectTo={process.env.GATSBY_SW_CAREER_PAGE} />
            </div>
            <div className='sw-about-us-right'>
                <img
                    src={'https://sw-assets.gumlet.io/about-us/about-img.jpeg?blur=30'}
                    data-src={'https://sw-assets.gumlet.io/about-us/about-img.jpeg'}
                    alt={'about us'}
                    loading='lazy'
                />
                <Button buttonText='JOIN OUR TEAM' redirectTo={process.env.GATSBY_SW_CAREER_PAGE} />
            </div>
        </div>
    );
};

export default Header;
